<template>
  <div class="relative bg-gray">
    <div class="sm:text-center lg:text-left top_block">
      <h2
        class="mb-4 lg:mb-10 text-xl tracking-tight leading-loose font-extrabold text-gray-900 sm:text-3xl sm:leading-tight full_text">
        {{ $t('header_message') }}
      </h2>

      <div class="add_text max-w-[798px] mb-10">
        Wyszukiwanie w rejestrze osób posiadających uprawnienia budowlane
      </div>
    </div>
    <div class="general" v-if="!this.$store.state.base.result">
    <div  class="items-start mt-10 mb-20 z-10">
        <div>
          <slot name="search"></slot>
        </div>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 z-0">
      <slot name="img"></slot>
    </div>
  </div>
  <div v-else>
  <Result></Result>
  </div>
  </div>
</template>

<script>
import Result from '@/components/Result.vue'

export default {
  name: 'HeroSection',
  components: { Result }
}
</script>
<style>
.container {
  grid-area: content;
  overflow-x: auto;
  overflow-y: auto;
  margin-left: 0px;
}
.top_block {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.general {
  margin-bottom: 100px;
  min-width: 1500px;
}
</style>
