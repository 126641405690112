<template>
  <div
    v-if="totalRecords"
    class="bg-white px-4 py-3 flex items-center justify-between
              border-t border-gray-200 sm:px-6 flex-row-reverse sm:flex-row"
  >
    <div class="w-1/5">
      <p class="text-sm leading-5 text-gray-700">
        {{ this.$t('results') }}: <span>{{ this.page }}</span>-{{ allPages }} / <span class="font-medium">{{ totalRecords }}</span>
      </p>
    </div>
    <div class="w-1/3 pagination_buttons">
    <button
      @click="firstPage"
      :disabled="page === 1" >
    <svg  width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_15791_800" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_15791_800)">
        <path d="M11.5 18L5.5 12L11.5 6L12.9 7.4L8.325 12L12.9 16.6L11.5 18ZM18.1 18L12.1 12L18.1 6L19.5 7.4L14.925 12L19.5 16.6L18.1 18Z" fill="#005CE6"/>
      </g>
    </svg></button>
    <button
      @click="previousPage"
      :disabled="page === 1" >
    <svg  width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.205 7.41L14.795 6L8.79501 12L14.795 18L16.205 16.59L11.625 12L16.205 7.41Z" fill="#005CE6"/>
    </svg></button>
    <div @click="lastPage(startPage+1)" v-html=this.getPage(startPage+1)></div>
    <div @click="lastPage(startPage+2)" v-html=this.getPage(startPage+2)></div>
    <div @click="lastPage(startPage+3)" v-html=this.getPage(startPage+3)></div>
    <div @click="lastPage(startPage+4)" v-html=this.getPage(startPage+4)></div>
    <button
      @click="nextPage"
      :disabled="page === allPages">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.205 6L8.79498 7.41L13.375 12L8.79498 16.59L10.205 18L16.205 12L10.205 6Z" fill="#005CE6"/>
    </svg></button>
    <button
      @click="lastPage(allPages)"
      :disabled="page === allPages">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_15791_815" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_15791_815)">
        <path d="M13.5 6L19.5 12L13.5 18L12.1 16.6L16.675 12L12.1 7.4L13.5 6ZM6.9 6L12.9 12L6.9 18L5.5 16.6L10.075 12L5.5 7.4L6.9 6Z" fill="#005CE6"/>
      </g>
    </svg></button>
    </div>
    <div class="w-1/3 to_right">
      <div>Pokaż na stronie
      <select @change="setPage">
        <option v-for="opt in allPages" v-bind:key="opt" :value=opt>{{opt}}</option>
      </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'Pagination',
  data: function() {
    return {
      searchButton:
        'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 ' +
        'font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue ' +
        'focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 disabled:hidden'
    }
  },
  computed: {
    ...mapState({
      totalRecords: state => state.professionalCards.totalRecords,
      page: state => state.selectedSet.pagination.page,
      limit: state => state.selectedSet.pagination.limit
    }),
    ...mapGetters({
      queryParams: 'selectedSet/getQueryParams'
    }),
    allPages() {
      return this.totalRecords && this.page
        ? Math.ceil(this.totalRecords / this.limit)
        : false
    },
    startPage() {
      return (Math.floor((this.page - 1) / 4) * 4)
    }
  },
  methods: {
    ...mapActions({
      search: 'professionalCards/getProfessionalCards'
    }),
    nextPage() {
      this.$store.commit('selectedSet/NEXT_PAGE')
      const params = this.queryParams
      this.search(params)
    },
    firstPage() {
      this.$store.commit('selectedSet/FIRST_PAGE')
      const params = this.queryParams
      this.search(params)
    },
    previousPage() {
      this.$store.commit('selectedSet/PREVIOUS_PAGE')
      const params = this.queryParams
      this.search(params)
    },
    lastPage(val) {
      this.$store.commit('selectedSet/LAST_PAGE', val)
      const params = this.queryParams
      this.search(params)
    },
    setPage(event) {
      this.$store.commit('selectedSet/SET_PAGE', event.target.value)
      const params = this.queryParams
      this.search(params)
      this.$forceUpdate()
    },
    getPage(PageNumber) {
      return (PageNumber > this.allPages) ? '' : (PageNumber === this.page) ? this.activePage(PageNumber) : this.inactivePage(PageNumber)
    },
    activePage(PageNumber) {
      return '<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.453125" width="32" height="32" rx="16" fill="#005CE6"/><text x="' + (12 - (PageNumber.toString().length * PageNumber.toString().length)).toString() + '" y="23" font-family="Roboto" font-size="16" fill="white">' + PageNumber + '</text></svg>'
    },
    inactivePage(PageNumber) {
      return '<svg class="svg_button" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.453125" width="32" height="32" rx="16" fill="#d5d5d7"/><text x="' + (12 - (PageNumber.toString().length * PageNumber.toString().length)).toString() + '" y="23" font-family="Roboto" font-size="16" fill="black">' + PageNumber + '</text></svg>'
    }
  },
  mounted() {
    const thisInstance = this
    this.$root.$on('reset_page', function() {
      thisInstance.$store.commit('selectedSet/LAST_PAGE', 1)
    })
  }
}
</script>
<style>
.pagination_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pagination_buttons:not(:disabled) {
  cursor: pointer; }
.to_right {
  display: contents;
  text-align: right;
}
.svg_button {
  cursor: pointer;
}
</style>
