<template>
   <div class="bg-white w-100 py-8 head_1">
    <div class="m-auto flex flex-wrap justify-left items-center">
      <a href="https://e-crub.gunb.gov.pl/">
        <img style="max-width: 140px; max-height: 40px" :alt="$t('header_logo')" src="../assets/logo.png" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>

<style>
.head_1 {
  width: 100%;
  height: 104px;
  padding: 32px 24px 32px 8%;
  border-radius: 5px 0px 0px 0px;
  background: white;
}
.head_common {
  align-items: center;
  padding: 0px 240px;
  gap: 16px;

  width: 100%;
  height: 48px;

  background: #333399;

}
</style>
