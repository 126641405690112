<template>
  <div>
  <div class="main_container_top items-start flex">
    <button class="button back_button text-white" @click="goBack()">Wyszukaj ponownie</button>
  </div>
  <div class="main_container_result">
    <div class="px-4 py-3 bg-white text-left sm:px-6 bg-grey">
      <div class="bg-grey">
        <div class="max-w-screen-xl py-3 px-3 sm:px-6 lg:px-8">
          <div class="flex items-center justify-between flex-wrap">
            <div class="w-0 flex-1 flex items-center">
              <p class="ml-3 font-medium text-black">
                {{ this.$t('table_info') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    <Table></Table>
    </div>
  </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'

export default {
  name: 'Result',
  components: { Table },
  methods: {
    goBack() {
      this.$store.commit('base/SET_RESULT')
      this.$root.$emit('reset_page')
      this.$forceUpdate()
    }
  }
}
</script>
<style>
.main_container_result {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  padding-top: 66px;
  padding-bottom: 66px;
  gap: 112px;
  width: 100%;
  height: max-content;
  color: #e2e8f0;
  min-width: 1500px;
}
.main_container_top {
  display: inline-block;
  flex-direction: row;
}

.back_button {
  /* Button */

  /* Auto layout */

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 28px;
  gap: 8px;

  position: static;
  width: 198px;
  height: 48px;
  left: 243px;
  top: 441px;

  background: #005CE6;
  border-radius: 48px;

}
.bg-gunb {
  background: #e2e8f0;
}
</style>
